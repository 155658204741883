import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, HashNavigation } from "swiper/modules";
import TestimonialsCards from "@/components/TestiMonialCards";
import { getAllTestiMonials } from "@/network/api";
function Testimonials() {
  const [tableData, setTableData] = useState([]);

  const getAllBlogsFromAPI = async () => {
    try {
      const resp = await getAllTestiMonials();
      console.log(resp, '>>>>>>>>>>')
      if (resp) {
        setTableData(resp);
      }
    } catch (error) {
      console.log(error)
    }
  };



  useEffect(() => {
    getAllBlogsFromAPI();
  }, []);
  return (
    <div className="  bg-[#878232] py-10  lg:min-h-[70vh]  md:min-h-[40vh] ">
      <div className="container mx-auto">
        <div>
          <h1 className="text-white  text-[30px] mt-10 sm:text-[64px] mb-12 sen text-center  font-extrabold cursor-default ">
            Testimonials
          </h1>
        </div>
        <div className="flex items-center justify-center">
          <Swiper
            spaceBetween={30}
            hashNavigation={{
              watchState: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation, HashNavigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              375: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 10,
              },

              1024: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 2,
                spaceBetween: 20,
              },

              1440: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
            }}
          >
            <div className="container mx-auto ">
              {tableData?.map((item, index) => {
                return (
                  <SwiperSlide data-hash="slide1 ">
                    <TestimonialsCards
                      name={item?.name}
                      image={item?.image}
                      details={item?.details}
                      even={index % 2 === 0 ? false : true}
                    />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
