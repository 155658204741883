import React, { useEffect } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
// import Swiper core and required modules
import { Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import Link from "next/link";

const HeroSection = ({ onNavigate }) => {
  var tl = gsap.timeline({ repeat: 0, repeatDelay: 2, paused: true });
  var textTl = gsap.timeline({
    repeat: 99999,
    repeatDelay: 0.0001,
    paused: true,
  });
  useGSAP(() => {
    tl.fromTo(
      ".honey_hive_text_content_where",
      {
        duration: 1,
        y: 200,
        opacity: 0,
        stagger: 0.5,
        ease: "power3.out",
        display: "hiiden",
      },
      {
        duration: 0.4,
        y: 0,
        opacity: 1,
        stagger: 0.5,
        ease: "power3.out",
        display: "block",
        delay: 0.5,
      }
    );
    tl.fromTo(
      ".honey_hive_text_content_Creativity",
      {
        duration: 0.1,
        y: 200,
        opacity: 0,
        stagger: 0.5,
        ease: "power3.out",
        display: "hiiden",
      },
      {
        duration: 0.4,
        y: 0,
        opacity: 1,
        stagger: 0.5,
        ease: "power3.out",
        display: "block",
      }
    );
    tl.fromTo(
      ".honey_hive_text_content_Meets",
      {
        duration: 0.1,
        y: 200,
        opacity: 0,
        stagger: 0.5,
        ease: "power3.out",
        display: "hiiden",
      },
      {
        duration: 0.4,
        y: 0,
        opacity: 1,
        stagger: 0.5,
        ease: "power3.out",
        display: "block",
      }
    );
    tl.fromTo(
      ".honey_hive_text_content_Opportunity",
      {
        duration: 0.1,
        y: 200,
        opacity: 0,
        stagger: 0.5,
        ease: "power3.out",
        display: "hiiden",
      },
      {
        duration: 0.6,
        y: 0,
        opacity: 1,
        stagger: 0.5,
        ease: "power3.out",
        display: "block",
      }
    );
    textTl.fromTo(
      ".Easier",
      {
        duration: 0.1,
        display: "none",
        ease: "easeIn",
        y: -20,
      },
      {
        duration: 1.6,
        ease: "power3.out",
        display: "block",
        delay: 0.1,
        y: 0,
      }
    );
    textTl.to(".Easier", {
      duration: 0.000001,
      display: "none",
      ease: "easeIn",
      y: 20,
    });

    textTl.fromTo(
      ".Simple",
      {
        duration: 0.000001,
        display: "none",
        ease: "easeIn",
        y: -20,
      },
      {
        duration: 1.6,
        ease: "power3.out",
        display: "block",
        delay: 0.1,
        y: 0,
      }
    );
    textTl.to(".Simple", {
      duration: 0.000001,
      display: "none",
      ease: "easeIn",
      y: 20,
    });
    textTl.fromTo(
      ".Faster",
      {
        duration: 0.0000001,
        display: "none",
        ease: "easeIn",
        y: -20,
      },
      {
        duration: 1.6,
        ease: "power3.out",
        display: "block",
        y: 0,
        delay: 0.1,
      }
    );
    textTl.to(".Faster", {
      duration: 0.0001,
      display: "none",
      ease: "easeOut",
      y: 20,
    });
  });

  useEffect(() => {
    tl.play();
    textTl.play();
  });

  return (
    <div className="min-h-[95vh] sm:min-h-[115vh]   overflow-hidden relative ">
      <div className="honey_hive_text_content flex flex-col items-start min-h-[70vh] sm:justify-end 2xl:justify-end lg:justify-end xl:justify-end  md:justify-start">
        <h2 className="honey_hive_text_content_where">Where</h2>
        <h2 className="honey_hive_text_content_Creativity "> Creativity </h2>
        <h2 className="honey_hive_text_content_Meets">Meets</h2>
        <h2 className="honey_hive_text_content_Opportunity">Opportunity.</h2>
      </div>
      <div className="honey_hive_text_content1">
        <h2 className="Getting_the_right">Getting the right</h2>
        <h2 className="talent_is_now">talent is now</h2>
      </div>
      <div className="rotation_img"></div>
      <div className="honey_hive_text_content2">
        <div className="relative">
          <div className="Easier max-h-[120px] absolute top-0 hidden    overflow-hidden ">
            Easier
          </div>
          <div className="Simple max-h-[120px] absolute top-0 hidden   overflow-hidden ">
            Simple
          </div>
          <div className="Faster max-h-[120px] absolute top-0 hidden   overflow-hidden ">
            Faster
          </div>
        </div>
      </div>

      <p
        onClick={onNavigate}
        className="down_arrow cursor-pointer z-10 hidden sm:block"
      >
        <svg
          className="cursor-pointer"
          width="78"
          height="78"
          viewBox="0 0 78 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="39" fill="#636363" fill-opacity="0.15" />
          <path
            d="M37.9393 59.0607C38.5251 59.6464 39.4749 59.6464 40.0607 59.0607L49.6066 49.5147C50.1924 48.9289 50.1924 47.9792 49.6066 47.3934C49.0208 46.8076 48.0711 46.8076 47.4853 47.3934L39 55.8787L30.5147 47.3934C29.9289 46.8076 28.9792 46.8076 28.3934 47.3934C27.8076 47.9792 27.8076 48.9289 28.3934 49.5147L37.9393 59.0607ZM37.5 20L37.5 58L40.5 58L40.5 20L37.5 20Z"
            fill="black"
          />
        </svg>
      </p>
    </div>
  );
};

export default HeroSection;
