import React from "react";

function index() {
  return (
    <div className="container parallax relative  overflow-hidden 2xl:flex lg:flex items-stretch  gap-10  min-h-[100vh] sm:max-h-[100vh] justify-between mx-auto cursor-default" >
      <div className="value_outer_container">
        <h1 style={{ zIndex: "20" }} className=" valueStroke  value_inner_container sen">
          The values we live by
        </h1>
      </div>

      <div className="min-w-[50%] min-h-[30vh] md:min-h-[60vh]"></div>
      <div className="flex sm:w-[50%] md:w-[100%]  flex-col w-full bg-[#EEEDE2] px-5 sm:px-8   sm:py-32 ">
        <div>
          <h1 className="mobile-text">
            Expertise
          </h1>
          <p className="value_content_descriptions">
            At Honeyhive we specialize in finding the right talent for your
            business. We utilize our expertise and experience to identify the
            skills and experience you need and match you with the right
            candidate.
          </p>
        </div>
        <div>
          <h1 className="mobile-text">
            Time Saving
          </h1>
          <p className="value_content_descriptions">
            Finding the right candidate for your business can be a
            time-consuming process. Honeyhive can save you time by handling the
            recruitment process, including screening resumes, interviewing
            candidates, and conducting background checks.
          </p>
        </div>
        <div>
          <h1 className="mobile-text">
            Flexibility
          </h1>
          <p className="value_content_descriptions">
            Creative staffing agencies offer flexible staffing solutions,
            allowing you to hire temporary, contract, or full-time employees
            based on your business needs. This flexibility helps you manage your
            workforce and adapt to changes in your business.
          </p>
        </div>
      </div>
    </div>
  );
}

export default index;
