import React, { useState, useEffect, useRef } from "react";
import ClinetsImage from "../../assests/clients.png";
import MobileClinets from "../../assests/Mobile_clinets.svg";
import Image from "next/image";

const OurClients = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div >
      <div className="bg-[#EEEDE2] sm:min-h-screen md:min-h-[60vh] cursor-default">
        <div className="flex justify-center items-center">
          <h1 className="text-[#5C489C] sm:text-9xl text-7xl font-extrabold leading-[74px] py-12">
            Our Clients
          </h1>
        </div>

        {/* Desktop version */}
        <div className="sm:flex slider  w-full mx-auto overflow-hidden p-[2.5rem] hidden justify-center items-center">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <img
              key={item}
              className="item aspect-auto"
              src={ClinetsImage.src}
              alt={`Client ${item}`}
            />
          ))}
        </div>

        {/* Mobile version */}
        <div
          className={`sm:hidden   w-full mx-auto overflow-hidden min-h-[40vh] p-[2.5rem] flex justify-center items-center`}
        
          onTouchEnd={()=>  setIsHovered(false)}
          onTouchStartCapture={()=>  setIsHovered(true)}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <Image
              key={item}
              style={{  animationPlayState:isHovered? 'paused' : ''}}
              className={` item max-w-none min-h-[40vh]`}
              width={2000}
              height={2000}
              src={MobileClinets}
              alt={`Client ${item}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurClients;
