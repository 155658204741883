import Axios from "./index";

const getAllBlogs = async () => {
  try {
    const response = await Axios.get("/blogs/get_all");
    return response?.data?.blogs;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw the error to be caught by the caller
  }
};
const getAllTestiMonials = async () => {
  try {
    const response = await Axios.get("/testimonials");
    return response?.data?.testimonials;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw the error to be caught by the caller
  }
};

const getBlogDetails = async (id) => {
  try {
    const response = await Axios.get(`/blogs/?id=${id}`);
    return response?.data?.blog;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};
const getTestimonialDetails = async (id) => {
  try {
    const response = await Axios.get(`/testimonials/${id}`);
    return response?.data?.testimonial;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

const postContactDetails = async (payload) => {
  try {
    const response = await Axios.post(`/contact`, payload);
    return response?.data;
  } catch (error) {
    console.error("POST error:", error);
    return error
  }
};

const postCarrerDetails = async (payload) => {
  try {
    const response = await Axios.post(`/carrer`, payload);
    return response?.data;
  } catch (error) {
    console.error("POST error:", error);
    return error
  }
};
const updateCarrerDetails = async (payload, id) => {
  try {
    const response = await Axios.put(`/carrer?id=${id}`, payload);
    return response?.data;
  } catch (error) {
    console.error("POST error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

export { getAllBlogs, getBlogDetails, postContactDetails, postCarrerDetails, updateCarrerDetails, getAllTestiMonials, getTestimonialDetails };
