import React from "react";

function Success() {

  return (
    <div className="sm:min-h-[80vh] bg-[#5C489C] flex flex-col sm:justify-center items-center py-24 cursor-default">
      <div className="container mx-auto">
        <div className="container mx-auto sm:py-8 p-2 text-center">
          <p className="text-white sm:text-4xl text-3xl text-justify sm:leading-[42px] leading-[24px]  m-[12px]">
            We are committed to excellence in finding the right talent for your
            business. Our Talent Sourcing service ensures that we find the
            perfect candidates to meet your needs. Our Backend Creative
            Mentoring helps your team to achieve their full potential by
            providing expert guidance from experienced professionals. Our
            Recruitment Consulting service delivers expert advice on recruitment
            strategy and process.
          </p>
        </div>
        <div className="text-white text-center text-5xl sm:text-[110px] leading-[120px] font-extrabold sm:my-24  my-12">
          <h1>Our Success</h1>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-4 align-baseline justify-between w-full  grid-cols-2 text-center gap-10">
          <div>
            <p className="text-[#ED9D21] my-2 font-extrabold sm:text-9xl text-7xl">
              150+
            </p>
            <p className="text-white text-3xl sm:text-6xl my-2 raleway mx-2">
              {" "}
              Projects completed
            </p>
          </div>

          <div>
            <p className="text-[#ED9D21] my-2 font-extrabold sm:text-9xl  text-7xl">
              130+
            </p>
            <p className="text-white text-3xl sm:text-6xl my-2 raleway">
              {" "}
              Happy clients
            </p>
          </div>
          <div>
            <p className="text-[#ED9D21] my-2 font-extrabold sm:text-9xl  text-7xl">
              25+
            </p>
            <p className="text-white text-3xl sm:text-6xl my-2 raleway">
              {" "}
              Awards received
            </p>
          </div>
          <div>
            <p className="text-[#ED9D21] my-2 font-extrabold sm:text-9xl  text-7xl">
              40+
            </p>
            <p className="text-white text-3xl sm:text-6xl my-2 raleway">
              {" "}
              Team members
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
