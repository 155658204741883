import React from "react";
import ClientImage from "../../assests/testimonials.png";
import DoubleTick from "../../assests/doubleTick.svg";
import Coma from "../../assests/coma.svg";

import Image from "next/image";
import ImageFrame from "../../assests/testiMonialFrame.svg";
const TestimonialsCards = ({ image, name, details, even }) => {
  return (
    <div
      style={{ justifyContent: even ? "flex-start" : "flex-end" }}
      className="sm:min-h-[40rem] flex min-h-[50rem] cursor-default   "
    >
      <div className="sm:min-h-[40rem] min-h-[50rem] flex sm:flex-row flex-col justify-center  gap-10 sm:items-start items-center  sm:max-w-[90%] ">
        <div className="sm:block hidden rotate-[180deg]">
          <Image src={DoubleTick} alt="honey_hive" width={60} height={60} />
        </div>
        <div>
          <div className="sm:hidden flex">
            <Image src={Coma} alt="honey_hive" width={30} height={30} />
          </div>
          <div className="flex  relative rounded-t-[136px] justify-center items-start">
            {/* <div className="sm:hidden flex">
              <Image src={Coma} alt="honey_hive" width={30} height={30} />
            </div> */}
            {/* <div className=" rounded-t-[50%] overflow-clip z-0  ">
              <Image src={image} alt="testimonial" width={200} height={200}  />
            </div>
            <div className="absolute left-[10px] sm:translate-x-4 translate-x-[10px] sm:-translate-y-0 z-10  translate-y-[-4px] ">
              <Image src={ImageFrame} alt="testimonial" />
            </div> */}
          </div>
        </div>

        <div className="flex flex-col sm:justify-end min-h-[27rem] items-start sm:items-start w-[80%] sm:w-[60%]">
          <p className="text-white raleway text-left sm:text-3xl leading-loose text-2xl max-w-[90%]  my-6 ">
            {details}
          </p>
          <div className="flex items-end gap-10 justify-between w-full">
            <p
              className="text-white text-left sm:text-5xl text-3xl 
raleway_italic"
            >
              {name || "Mr. Name Kumar"}
            </p>
            <div className="sm:block hidden">
              <Image src={DoubleTick} alt="honey_hive" width={50} height={50} />
            </div>
            <div className="sm:hidden flex">
              <Image src={DoubleTick} alt="honey_hive" width={30} height={30} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCards;
