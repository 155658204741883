"use client";
import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";

import OurClients from "./OurClients";
import Testimonial from "./testiMonials";
import Values from "./Values";
import Success from "./Success";
import Resources from "./resources";
import HeroSection from "./heroSection";

const HomePage = () => {
  const router = useRouter();
  const sectionsRef = useRef({}); // Store section references

  // Function to handle scroll with -30px offset
  const scrollToSection = (id) => {
    const element = sectionsRef.current[id];
    if (element) {
      const topOffset =
        element.getBoundingClientRect().top + window.scrollY - 130; // -30px offset
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  // Handle hash-based navigation on page load
  useEffect(() => {
    const { hash } = router.asPath;
    if (hash) {
      const id = hash.replace("#", "");
      scrollToSection(id);
    }
  }, [router.asPath]);

  return (
    <div>
      {/* Hero Section */}
      <div
        className="z-50"
        ref={(el) => (sectionsRef.current["hero"] = el)}
      >
        <HeroSection
          onNavigate={() => scrollToSection("resources")} // Trigger scroll to Resources
        />
      </div>

      {/* Resources Section */}
      <div
        className="z-50"
        id="resources"
        ref={(el) => (sectionsRef.current["resources"] = el)}
      >
        <Resources />
      </div>

      {/* Other Sections */}
      <div
        className="z-50"
        ref={(el) => (sectionsRef.current["success"] = el)}
      >
        <Success />
      </div>

      <Values />

      <div
        className="z-50"
        ref={(el) => (sectionsRef.current["testimonials"] = el)}
      >
        <Testimonial />
      </div>

      <div
        className="z-50"
        ref={(el) => (sectionsRef.current["clients"] = el)}
      >
        <OurClients />
      </div>
    </div>
  );
};

export default HomePage;
